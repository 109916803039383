<template>
  <div>
    <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    />

    <div class="d-none d-xl-block">
      <div class="row">
        <div class="col-xl-8 planning">
          <a 
            href="javascript:void(0)" 
            class="btn btn-warning add-event" 
            @click.prevent="initForm"
            data-toggle="modal"
            data-target="#add-event"
          >
            <i class="icofont icofont-plus-circle" />
          </a>
          <FullCalendar :options="calendarOptions" />
        </div>
        <div class="col-xl-4">
          <event-detail :event="selectedEvent" :detailStyle="detailStyle" v-if="selectedEventUid !== null" />
        </div>
      </div>
    </div>
    <div class="d-xl-none d-md-block">
      <div class="row">
        <div class="col-12 planning">
          <a 
            href="javascript:void(0)" 
            class="btn btn-warning add-event" 
            @click.prevent="initForm"
            data-toggle="modal"
            data-target="#add-event"
          >
            <i class="icofont icofont-plus-circle" />
          </a>
          <FullCalendar :options="calendarOptions" />
          <transition name="slide-fade">
            <div class="detail-box" v-if="selectedEventUid !== null">
              <a href="javascript:void(0)" @click.prevent="initSelectedEventUid">
                  <i class="icofont icofont-close icofont-2x"></i>
              </a>
              <event-detail :event="selectedEvent" :detailStyle="detailStyle"   />
            </div>
          </transition>
        </div>
        
      </div>
    </div>
    
    <modal 
      id="add-event"
      title="Ajouter un evenement"
      size="large"
      :footer="false"
    >
      <div class="form-group">
        <label for="add-type">Type Evenement</label>
        <select 
          class="form-control"
          v-model="type"
          v-select="{placeholder: 'Selectionnez le type d\'evenement'}"
        >
          <option />
          <option
            v-for="(t,index) in typeEvents"
            :key="index"
            :value="t.uid"
          >
            {{ t.libelle }}
          </option>
        </select>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="checkbox-list">
              <label class="checkbox">
              <input type="checkbox" name="animal-inconnu" v-model="animalInconnu">
              <span></span> Nouvel animal</label>
          </div>
        </div>
        <div class="col-6">
          <div class="checkbox-list">
              <label class="checkbox">
              <input type="checkbox" name="allDay" v-model="allDay">
              <span></span> Toute la journée</label>
          </div>
        </div>
      </div>
      <animal-detail :show-detail="true"  v-if="!animalInconnu"/>
      <div class="row"  v-if="isRDV">
        <div class="col-12">
          <div class="form-group">
            <label>Motif du rendez-vous</label>
            <select
              class="form-control"
              v-model="motif"
              v-select="{placeholder: 'Selectionnez le motif de rendez-vous'}">
            <option></option>
            <option v-for="(m,index) in motifs" :key="index" :value="m.uid">{{ m.libelle }}</option>
          </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="add-libelle">Titre</label>
            <input class="form-control" name="add-libelle" id="add-libelle"  v-model="libelle" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="add-startDate">Date de début</label>
            <input v-model="startDate" type="text" name="add-startDate" id="add-startDate" class="form-control">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="add-description">Description</label>
            <textarea v-model="description" class="form-control" name="add-description" id="add-description" rows="4"></textarea>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="form-group text-right">
            <a class="btn btn-third" href="javascript:void(0)" @click.prevent="addEvent">Ajouter</a>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import moment from 'moment'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import bootstrapPlugin from '@fullcalendar/bootstrap';
import frLocal from '@fullcalendar/core/locales/fr'
import flatpickr from "flatpickr";
import { French } from "flatpickr/dist/l10n/fr.js"
require("flatpickr/dist/themes/material_green.css");
import Navbar from '../../components/navbar.vue'
import Modal from '../../components/modal.vue'
import AnimalDetail from '../../components/identification/animal/detailInform.vue'
import EventDetail from '../../components/events/eventDetails.vue'
import '@fortawesome/fontawesome-free/css/all.css'
import { Select } from '../../directive/select'
import {EVENT_ALL_DAY_DURATION, EVENT_DURATION, EVENT_UPDATE_TOLERANCE} from '../../constants/events'
import { ADD_EVENT } from '../../graphql/events'
export default {
    components: { FullCalendar, Navbar, Modal, AnimalDetail, EventDetail },
    directives: {Select},
    data(){
        return {
            navbarItems: [
              {
                libelle: 'Rendez-vous'
              }
            ],
            pageIcon: 'la-calendar',
            pageTitle: 'Rendez-vous',
            pageDescription: 'Organiser les Rendez-vous sur CASEAC',
            libelle: null,
            description: null,
            startDate: null,
            endDate: null,
            type: null,
            selectedEvent: null,
            // selectedEventUid: null,
            detailStyle: null,
            motif: null,
            hasError: false,
            error_msg: null,
            animalInconnu: true,
            allDay: false,
            //events: []
        }
    },
    watch: {
      allDay(){
        let options = {
          enableTime: !this.allDay,
          "locale": French
        }
        flatpickr("#add-startDate", options)
      },
      startDate(){
        if(!this.allDay){
          this.endDate = new Date( new Date(this.startDate).getTime() + parseInt (EVENT_DURATION) * 60000);
        }else{
          this.endDate = new Date(new Date(this.startDate).getTime() + parseInt (EVENT_ALL_DAY_DURATION) * 60000);
        }
        
      },
      motif(){
        if(this.motif !== null && this.selectedAnimal !== null){
          let animal = this.animaux.find(item => item.uid === this.selectedAnimal)
          let motif = this.motifs.find(item => item.uid === this.motif)
          this.libelle =` ${motif.libelle} - ${animal.name}`
        }
        else{
          this.libelle = null
        }
      }
    },
    mounted(){
      let options = {
        enableTime: true,
        "locale": French
      }
      flatpickr("#add-startDate", options)
      
    },
    methods: {
      ...mapMutations({
        done: 'DONE',
        setLoading: 'SET_LOADING',
        setSelectedEventUid: 'events/SET_SELECTED_EVENT_UID'
      }),
      initSelectedEventUid(){
        this.setSelectedEventUid(null)
      },
      initForm(){
        this.setLoading(false)
        this.libelle = null
        this.type = null
        this.allDay = false
        this.startDate = null
        this.endDate = null
        this.description = null
        this.hasError = false
        this.error_msg = null
      },
      handleDateClick(){
          console.log('Adding event')
      },
      addEvent(){
        this.setLoading(true)
        let data = {
          type: this.type,
          libelle: this.libelle,
          allDay: this.allDay,
          startDate: this.startDate,
          endDate: this.endDate,
          description: this.description,
          private: false,
          infoUtile: JSON.stringify({
            animalInconnu: this.animalInconnu,
            animal: this.selectedAnimal,
            motif: this.motif
          })
        }
        this.$apollo.mutate({
                mutation: ADD_EVENT,
                variables: {
                    "event": {
                        ...data
                    }
                },
                update: (data) => {
                    console.log(`Event add successfully on uid ${data.uid}`)
                }
              }).then(() => {
                this.initForm()
            }).catch((error) => {
                this.setLoading(false)
                console.error(error)
                this.has_error = true
                this.error_msg = error
            })
        console.log(data)
        this.done()
      },
      
    },
    computed: {
        ...mapGetters({
            typeEvents: 'events/types',
            motifs: 'sanitaire/motifConsultations',
            selectedAnimal: 'identification/selectedAnimal',
            animaux: 'identification/animaux', 
            events: 'events/events',
            selectedEventUid: 'events/selectedEventUid'
        }),
        sortedEvents(){
          return [...this.events].map(item => {
            let type = this.typeEvents.find(el => el.uid === item.type)
            let bg = (type.code === 'rdv') ? 'bg-success':'bg-primary'
            let date = new Date()
            let startAt = new Date(item.startDate)
            bg = (date.getTime() > startAt.getTime()) && !item.accomplished ? 'bg-warning':bg
            bg = ((date.getTime() - startAt.getTime())/1000 > EVENT_UPDATE_TOLERANCE) && !item.accomplished ? 'bg-danger':bg
            return {
              allDay: item.allDay,
              uid: item.uid,
              id: item.uid,
              title: item.libelle,
              start: item.startDate,
              end: item.endDate,
              description: item.description,
              className: bg,
            }
          })
          .sort((b,a) => moment(a.startDate) - moment(b.startDate))
        },
        isRDV(){
          let t = this.typeEvents.find(item => item.uid === this.type)
          if(t) return t.code === 'rdv'
          return false
        },
        newDate(){
          return new Date()
        },
        monthArray (){
          return [ '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12' ]
        },
        calendarOptions(){
          return {
            plugins: [ dayGridPlugin, timeGridPlugin, interactionPlugin, bootstrapPlugin ],
            themeSystem: 'bootstrap',
            locale: frLocal,
            initialView: 'dayGridMonth',
            weekends: true,
            editable: false,
            dateClick: this.handleDateClick,
            headerToolbar: {
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
            },
            events: this.sortedEvents,
            eventClick: ({event}) => {
              this.selectedEvent = event
              this.setSelectedEventUid(event.id)
              this.detailStyle = event.classNames
            },
          }
        } 
    }
}
</script>

<style lang="scss">
.slide-fade-enter-active {
  transition: all 0.8s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.planning{
    position: relative;
    a.add-event{
        position: absolute;
        top: 2px;
        left: 220px;
        margin-left: 10px;
    }
    .detail-box{
      position: absolute;
      z-index: 9;
      right: 5px;
      top: 0px;
      width: 60%;
      background-color: #fff;
      box-shadow: rgba(0,0,0,0.15) 3px 3px 2px 5px;
      a{
        position: absolute;
        right: 8px;
        top: 8px;
        font-weight: bold;
        z-index: 10;
      }
    }
    
}
</style>
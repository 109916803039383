<template>
  <div class="event-detail" :class="detailStyle.join(' ')">
    <div class="row event-detail-content">
        <div class="col-12">
            <div class="row">
                <div class="col-12">
                    <div class="photo" :style="showAnimalPhoto"></div>
                </div>
            </div>
            <div class="row event-header">
                <div class="col-8">
                    <h6>{{ eventObject.libelle }}</h6>
                </div>
                <div class="col-4 text-right">
                    {{ motif }}
                </div>
            </div>
            <div class="row">
                <div class="col-12 allDay">
                    <div class="badge" :class="eventObject.allDay ? 'badge-success': 'badge-danger'">
                        Toute la journée
                    </div>
                </div>
                <div class="col-6">
                    <label>Date de début</label>
                    <p>{{ eventObject.startDate|systemDateTime }}</p>
                    
                </div>
                <div class="col-6">
                    <label>Date de fin</label>
                    <p>{{ eventObject.endDate|systemDateTime }}</p>
                </div>
                <div class="col-12">
                    <label>Description</label>
                    <p>{{ eventObject.description }}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12 animal" v-if="hasAnimal">
                    <h6>Animal</h6>
                    <div class="row">
                        <div class="col-6">
                            <label>Nom</label>
                            <p>{{ animal.name }}</p>
                        </div>
                        <div class="col-6">
                            <label>CodeIso</label>
                            <p>{{ animal.codeIso }}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <label>Espèce</label>
                            <p>{{ espece }}</p>
                        </div>
                        <div class="col-6">
                            <label>Race</label>
                            <p>{{ race }}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <label>Age</label>
                            <p>{{ animal.dateNaissance|age }}</p>
                        </div>
                        <div class="col-6">
                            <label>Poids</label>
                            <p>{{ poids }}Kg</p>
                        </div>
                    </div>
                </div>

                <div class="col-12 proprietaire" v-if="hasProprio">
                    <h6>Propriétaire</h6>
                    <div class="row">
                        <div class="col-6">
                            <label>Nom Complet</label>
                            <p>{{proprio.fullname}}</p>
                        </div>
                        <div class="col-6">
                            <label>Télephone</label>
                            <p>{{proprio.telephone}}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <label>Email</label>
                            <p>{{proprio.email}}</p>
                        </div>
                        <div class="col-6">
                            <label>Adresse</label>
                            <p>{{proprio.address}}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-4">
                    <a href="javascript:void(0)" class="btn btn-sm btn-outline-primary" v-if="canUpdate"> 
                        <i class="icofont icofont-pencil"></i> 
                        Modifier
                    </a>
                </div>
                <div class="col-4">
                    <a href="javascript:void(0)" @click.prevent="validateEvent" :to="{name: 'add_anamnese_uid', params: {uid: event.id}}"  class="btn btn-sm btn-outline-success" v-if="canAccomplish"> 
                        <i class="icofont icofont-check"></i> 
                        Valider
                    </a>
                </div>

                <div class="col-4">
                    <a href="javascript:void(0)" @click.prevent="deleting" class="btn btn-sm btn-outline-danger" v-if="canCancel"> 
                        <i class="icofont icofont-close"></i> 
                        Annuler
                    </a>
                </div>
                
            </div>

            <div class="row user-box">
                <div class="col-12 user-box"> <div class="create-by"><user-inline :uid="createBy" /></div> </div>
            </div>

        </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import UserInline from '../admin/user/userInline.vue'
import moment from 'moment'
import { DELETE_EVENT, ACCOMPLISHED_EVENT } from '../../graphql/events';
import { EVENT_UPDATE_TOLERANCE } from '../../constants/events';
const animalPlaceholder = require('../../assets/img/animalPlaceholder.png')
export default {
    props: {
        event: {type: Object, required: true},
        detailStyle: {type: Array, required: true},
    },
    components: {UserInline}, 
    data(){
        return {
            
        }
    },
    methods: {
        ...mapMutations({
            setSelectedEventUid: 'events/SET_SELECTED_EVENT_UID'
        }),
        validateEvent(){
            this.$apollo.mutate({
                mutation: ACCOMPLISHED_EVENT,
                variables: {
                    "uid": this.eventObject.uid,
                    "accomplished": true
                },
                update: (data) => {
                    console.log(`Event accomplished successfully on uid ${this.eventObject.uid} with return ${data.deleteEvent}`)
                }
              }).then(() => {
                if(this.animal === null){
                    this.$router.push({name: 'add_animal'})
                }else{
                    this.$router.push({name: 'add_anamnese_uid', params: {uid: this.eventObject.uid}})
                }
            }).catch((error) => {
                console.log(error)
            })
            
        },
        deleting(){
            this.$apollo.mutate({
                mutation: DELETE_EVENT,
                variables: {
                    "uid": this.eventObject.uid
                },
                update: (data) => {
                    console.log(`Event detelted successfully on uid ${this.eventObject.uid} with return ${data.deleteEvent}`)
                }
              }).then(() => {
                this.setSelectedEventUid(null)
            }).catch((error) => {
                console.log(error)
            })
        }
    },
    computed: {
        ...mapGetters({
            events: 'events/events',
            selectedEventUid: 'events/selectedEventUid',
            typeEvents: 'events/types',
            motifs: 'sanitaire/motifConsultations',
            animaux: 'identification/animaux',
            proprietaires: 'auth/proprietaires',
            constantes: 'identification/constantes',
            races: 'identification/races',
            especes: 'identification/especes'
        }),
        eventObject(){
            let e = this.events.find(item => item.uid === this.event.id)
            if(e) return e
            return null
        },
        hasEvent(){
            return this.eventObject !== null && this.eventObject !== undefined
        },
        createBy(){
            if(this.hasEvent) return this.eventObject.createBy
            return null
        },
        motif(){
            if(this.hasEvent){
                let info = JSON.parse(this.eventObject.infoUtile)
                if(info.motif !== null) {
                    let m = this.motifs.find(item => item.uid === info.motif)
                    return m.libelle
                }
            }
            return '-'
        },
        animal(){
            if(this.hasEvent){
                let info = JSON.parse(this.eventObject.infoUtile)
                if(info.animal !== null) {
                    let a = this.animaux.find(item => item.uid === info.animal)
                    return a
                }
            }
            return null
        },
        showAnimalPhoto(){
            if(this.hasAnimal){
                if(this.animal.photo !== null) {
                    let p = JSON.parse(this.animal.photo); 
                    return {
                        "background-image": `url("${p.url}")`
                    }
                }
                
            }
            return {
                "background-image": `url("${animalPlaceholder}")`
            }
            
        },
        hasAnimal(){
            return this.animal !== null && this.animal !== undefined
        },
        espece(){
            let e = this.especes.find(item => item.uid === this.animal.espece)
            if(e) return e.libelle
            return '-'
        },
        race(){
            let r = this.races.find(item => item.uid === this.animal.race)
            if(r) return r.libelle
            return '-'
        },
        proprio(){
            if(this.hasAnimal){
                let p = this.proprietaires.find(item => item.uid === this.animal.proprio)
                if (p) return p
            }
            return null
        },
        hasProprio(){
            return this.proprio !== null && this.proprio !== undefined
        },
        poids(){
            let constante = [...this.constantes].
                                filter(item => item.animal === this.animal.uid).
                                map(item => {
                                    if(item.operationDate === null) return {poids: item.poid, date: item.createdAt}
                                    else return {poids: item.poid, date: item.operationDate}
                                }).
                                sort((b,a) => moment(a.date) - moment(b.date))
            if(constante.length > 0) return constante[0].poids                    
            return '-'
        },
        isAccomplished(){
            if(this.hasEvent) return this.eventObject.accomplished
            return false
        },
        canAccomplish(){
            if(!this.isAccomplished){
                let date = new Date()
                let startAt = new Date(this.eventObject.startDate)
                return date.getTime() > startAt.getTime()
            }
            return false
        },
        canUpdate(){
            if(!this.isAccomplished){
                let date = new Date()
                let startAt = new Date(this.eventObject.startDate)
                return (startAt.getTime() - date.getTime())/1000 > EVENT_UPDATE_TOLERANCE
            }
            return false
        },
        canCancel(){
            if(!this.isAccomplished){
                let date = new Date()
                let startAt = new Date(this.eventObject.startDate)
                return (startAt.getTime() - date.getTime())/1000 > EVENT_UPDATE_TOLERANCE
            }
            return false
        }
    }

}
</script>

<style lang="scss" scoped>
.event-detail {
    width: 100%;
    display: flex;
    box-shadow: 5px 2px 1px;
    .photo{
       background-repeat: no-repeat;
       background-size: contain;
       background-position: center;
       height: 200px;
    }
    .event-detail-header{
        padding-bottom: 20px;
        margin-bottom: 20px;
       
        a{
            position: absolute;
            right: 5px;
        }
    }
    .event-detail-content{
        margin: 0px 0px 0px 5px;
        background: #fff;
        color: rgba(0,0,0,0.5);
        .event-header{
            padding: 10px 0px;
            margin-bottom: 20px;
            border-top: 1px solid rgba(0,0,0,0.15);
            border-bottom: 1px solid rgba(0,0,0,0.15);
        }
        .allDay{
            margin-bottom: 20px;
            .badge{
                padding: 25px
            }
        }

    }
    .user-box{
        margin-top: 40px;
        position: relative;
        padding-top: 40px;
        display: block;
        .create-by{
            margin-top: 40px;
            position: absolute;
            right: 20px;
            bottom: 5px;
        }  
    }
    .animal{
        margin: 20px 0px;
        h6{
            padding-bottom: 5px;
           border-bottom: 1px solid rgba(0,0,0,0.1);
        }
    }
    .proprietaire{
        margin-bottom: 20px;
        h6{
            padding-bottom: 5px;
            border-bottom: 1px solid rgba(0,0,0,0.15);
        }
    }
}

</style>